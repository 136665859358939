<template>
  <Page id="about" :class="mq">
    <section
      class="section-indent about true-center page-top"
      aria-labelledby="aria-about"
    >
      <h1 id="aria-about">About This Website</h1>
      <div class="true-center">
        <p>
          This is a demo website ONLY and is designed to serve as a showcase of
          VISCREO&#39;s technical and design capabilities. As such, all designs,
          and content of this page are the property of VISCREO and sit under our
          copyright. Images were sourced from
          <a href="https://unsplash.com/" target="_blank">Unsplash</a> or
          <a href="https://pixabay.com/" target="_blank">Pixabay</a>
          and logos from the “<b>Our Partners</b>” section were sources from
          <a href="https://logoipsum.com/" target="_blank">Logoipsum</a>.
        </p>
        <p>
          The text content on this website is for design purposes only and
          serves as a way of better visualising what a live product could look
          like. In some cases, placeholder text (Lorem Ipsum) was used to
          simulate real text.
        </p>
      </div>
    </section>

    <section
      class="section-indent about true-center"
      aria-labelledby="aria-about-casestudy"
    >
      <h1 id="aria-about-casestudy">Case Study</h1>
      <div class="true-center">
        <p>
          Happy Dental was designed with the intention of modernising the web
          presence of independent dental practices. Happy Dental is a local
          independent practice that offers various dental services to customers
          of all ages. As such, they required a website that would be easily
          accessible to all demographics and device types.
        </p>

        <p>
          A culmination of clear typography, simple colour scheming and generous
          white spacing has resulted in a very clean and professional website -
          while retaining the friendly and approachable vibe that the customer
          requested.
        </p>

        <p>
          During our analysis of surrounding dental practices, we also
          discovered that around
          <b>50% of the websites did not have a valid SSL certificate</b>. Not
          only should this be a key requirement for any modern website, but this
          also becomes a lot more substantiated when you begin to consider the
          sensitive nature of health data being submitted on through the
          website.
        </p>

        <p>
          A lack of secure websites in this field should serve to highlight the
          importance of choosing a proactive web development company. Here at
          VISCREO, we don&#39;t just offer “fit and forget” solutions, we
          continually review and evaluate all our customers to ensure their
          designs are keeping up with the latest trends. We also only use
          trusted and respected hosting providers, ensuring that your website
          remains secure not just this year but every year.
        </p>

        <p>
          Like the quality of our work and are interested in how we can help
          transform your business? Get in touch with us today and we&#39;d be
          happy to run a free and independent design &amp; security audit of
          your website. No commitments needed other than a call with a member of
          our friendly team!
        </p>
      </div>
      <div v-if="!isMobile" />
      <div id="about-row">
        <Button value="Call Us" @click="nav" :full="true" :accent="true" />
        <Button value="Visit Our Website!" @click="nav" :full="true" />
      </div>
    </section>

    <Report />
  </Page>
</template>

<script>
import Button from "@/components/global/Button.vue";
import Page from "@/components/core/Page.vue";
import Report from "@/components/Report.vue";
import mixins from "@/javascript/mixins.js";

export default {
  inject: ["mq"],
  mixins: [mixins],
  components: {
    Button,
    Report,
    Page,
  },
  methods: {
    nav() {
      window.open("https://viscreo.co.uk/");
    },
  },
};
</script>

<style scoped>
.about {
  flex-direction: column;
  text-align: center;
  font-weight: 300;
  display: flex;
  gap: 40px;
}

.about > div {
  flex-direction: column;
  gap: 40px;
}

#about-row {
  flex-direction: row;
  display: flex;
  width: 100%;
  gap: 40px;
}

.brTablet #about-row {
  gap: 20px;
}

.brMobile #about-row {
  flex-direction: column;
  gap: 20px;
}
</style>
